//
// _badge.scss
//

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  // &[href] {
  //   @include hover-focus {
  //     color: $bg;
  //     text-decoration: none;
  //     background-color: rgba($bg, 0.4);
  //   }
  // }
}

@each $color, $value in $theme-colors {
  .badge-soft-#{$color} {
    @include badge-variant-soft($value);
  }
}

.rounded-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
}

.bg-draft {
  background-color: #f8f9fa !important;
}

.bg-submitted {
  background-color: #ffc107 !important;
}

.bg-published {
  background-color: #00b82b !important;
}

.bg-waiting-approval {
  background-color: #24e751 !important;
}

.bg-approved {
  background-color: #21983d !important;
}

.bg-liquidate-in-progress {
  background-color: #0fc8e4 !important;
}

.bg-liquidated,
.bg-confirmed {
  background-color: #17a2b8 !important;
}

.bg-converted,
.bg-confirmada {
  background-color: #28a745 !important;
}

.bg-consumed,
.bg-completed {
  background-color: #6c757d !important;
}

.bg-blocked,
.bg-rejected,
.bg-canceled,
.bg-expired,
.bg-expirada,
.bg-invalidated,
.bg-disabled {
  background-color: #dc3545 !important;
}

.bg-created,
.bg-criada,
.bg-requested {
  background-color: #ffc107 !important;
}

.bg-batch-revision {
  background-color: #b2b2b2 !important;
}

.bg-manual-revision {
  background-color: #000 !important;
}

.bg-batch-issued {
  background-color: #2d5ff5 !important;
}

.bg-manual-issued {
  background-color: #233e90 !important;
}
